.header-wrapper {
    .row {
        padding: 0;
        margin: 0;
    }

    .container-fluid {
        padding: 0;
    }

    .carousel {
        padding: 0;
        margin: 0;

        .row {
            margin-right: 0;
        }

        .header-background {
            background-size: cover;
            height: 700px;
            position: relative;
            z-index: 0;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.3);

            }

            .title {
                font-family: 'Roboto-Bold', sans-serif;
                font-size: 60px;
                line-height: 70px;
                color: #fff;
                font-weight: 900;
                margin-bottom: 30px;
                text-transform: uppercase;
                position: absolute;
                bottom: 40px;
                left: 100px;
                top: 40%;
                width: 50%;
                z-index: 1;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 25%;
                height: 100%;
                border-color: transparent transparent rgba(12, 12, 12, 0.6) rgba(12, 12, 12, 0.6);
                border-style: solid;
                border-width: 1100px 700px 1000px 700px;
            }
        }

        .owl-nav {
            margin: 0;

            div {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                height: 50px;
                text-align: center;
                color: rgba(255, 255, 255, 0.6);
                line-height: 50px;
                border: 1px solid rgba(255, 255, 255, 0.6);
                border-radius: 0;
                transition: all 0.3s ease-in;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    color: #f3c556;
                    border-color: #f3c556;
                    background: transparent;
                }

                &.owl-next {
                    left: auto;
                    right: 20px;
                }
            }
        }
    }
}

@media (max-width: 1255px) {
    .header-wrapper {
        .carousel {
            .header-background {
                height: 600px;
            }

            .owl-item {
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .header-wrapper {
        .carousel {
            .header-background {
                height: 500px;

                .title {
                    font-size: 35px;
                    line-height: 35px;
                }
            }

            .owl-nav {
                div {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .header-wrapper {
        .carousel {
            .header-background {
                height: 325px;
            }
        }
    }
}
