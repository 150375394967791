.header-static {
    background-size: cover;
    background-image: url('../../assets/images/01.jpg');
    height: 500px;
    position: relative;
    z-index: 0;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(12, 12, 12, 0.6);
    }

    .title {
        position: absolute;
        top: 100px;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto-Bold', sans-serif;
        font-size: 50px;
        color: #fff;
        text-transform: uppercase;
    }
}
