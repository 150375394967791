.gallery {
    .row {
        padding: 10px 0;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            padding: 10px 0;
        }
    }

}