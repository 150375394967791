.navbar-top.navbar {
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    min-height: 100px;
    z-index: 2;

    .navbar-toggler {
        border-color: #fac033;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250, 192, 51, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }

    .logo {
        height: 70px;
        width: 100px;
        cursor: pointer;
    }

    .navbar-nav {
        margin-left: 50px;

        .nav-link {
            color: #fff;
            font-family: 'Roboto-Bold', sans-serif;
            text-transform: uppercase;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 3px;
            margin: 0 10px;

            &:hover {
                color: #fac033;
            }

            &.active {
                color: #fac033;
                border-bottom: 2px solid #fac033;
            }
        }
    }
}

@media (max-width: 991px) {
    .navbar-top.navbar {
        .navbar-nav {
            margin-left: 0;
        }
    }
}

@media (max-width: 768px) {
    .navbar-top.navbar {
        top: 100px;
    }
}
