.apply-wrapper {
    .title {
        font-weight: 700;
        font-style: normal;
        font-family: 'Roboto-Bold', sans-serif;
        color: #140b05;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: 0;
    }

    .description-header {
        font-weight: 600;
        font-style: normal;
        font-family: 'Roboto', sans-serif;
        color: #474747;
        font-size: 20px;
        margin: 45px 0 10px 0;
    }

    .conditions {
        font-weight: 600;
        font-style: normal;
        font-family: 'Roboto', sans-serif;
        color: #474747;
        font-size: 18px;
        margin-bottom: 40px;

        li {
            list-style: none;
            display: flex;
            align-content: center;
            min-height: 30px;

            &::before {
                content: '';
                height: 0;
                width: 15px;
                border: 1px solid #fac033;
                margin: 12px;
                display: inline-block;
            }
        }
    }

    .form-container {
        background-color: rgba(232, 232, 232, 0.8);
        transition-duration: 0.4s;

        .form-field {
            background-color: #ffffff;
            font-style: normal;
            font-weight: 400;
            border-color: #e8e8e8;
            padding: 16px 22px;
            line-height: normal;
            outline: none;
            border-width: 1px;
            border-style: solid;
            color: #a2a2a2;
            font-size: 14px;
        }

        .h-50 {
            height: 50px;
        }
        ::placeholder {
            color: #a2a2a2 !important;
            font-size: 14px;
        }
        .form-field.touched.invalid {
            color: #a2a2a2;
            background-color: #ffffff;
            border-color: #ed1d24;
        }

        .invalid {
            color: #ed1d24;
            font-size: 12px;
        }

        .agree-checkbox {

            > input[type="checkbox"],
            > label {
                cursor: pointer;
            }
        }

        .submit-button {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-style: normal;
            color: #ffffff;
            background-color: #fac033;
            border-color: transparent;
            min-width: 180px;
        }

        .submit-button[disabled] {
            background-color: #ead3a0;
        }

        .date-container {
            border: none;
            box-sizing: border-box;
            outline: 0;
            position: relative;
            width: 100%;
            user-select: none;

            input[type="date"] {
                user-select: none;

                &::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                    user-select: none;
                }

                &:before {
                    content: attr(placeholder) !important;
                    color: #aaa;
                    margin-right: 0.5em;
                }

                &:focus:before,
                &:valid:before {
                    content: "";
                }
            }
        }

        .captcha {
            margin-top: 20px;
        }
    }
}

@media (max-width: 992px) {
    .apply-wrapper {
        .apply-row {
            margin: 0 -12px !important;

            .title {
                text-align: center;
            }

            .form-container {
                .form {
                    padding-left: 1rem!important;
                    padding-right: 1rem!important;
                }
            }
        }
    }
}
