.content-wrapper {
    position: relative;

    &:before {
        content: '';
        background-color: #fff;
        background-image: url('../../assets/images/world.png');
        opacity: 0.1;
        position: absolute;
        width: 100%;
        height: 90%;
        top: 0;
        right: 0;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .points {
        margin: 50px 0 10px;
        z-index: 0;

        .point-item {
            display: flex;
            margin: 10px 100px;
            min-height: 260px;

            .icon {
                display: flex;
                justify-content: center;
                font-size: 50px;
                margin-right: 20px;
                color: #f3c556;
            }

            .content {
                color: rgb(12, 12, 12);

                .title {
                    font-family: 'Roboto-Bold', sans-serif;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .content-wrapper {
        .points {
            .point-item {
                margin: 10px;
            }
        }
    }
}
