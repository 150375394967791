.information-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: transparent;
    min-height: 55px;
    border-bottom: 1px solid rgba(12, 12, 12, 0.6);
    display: flex;
    align-items: center;

    .top-bar-inner {
        display: flex;
        justify-content: space-between;

        .left-content {
            display: flex;

            .telephones,
            .email {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                border: transparent;
                color: #fac033;

                &:focus {
                    box-shadow: none;
                }
            }

            .telephones {
                font-size: 16px;
            }
            .email {
                font-size: 19px;
            }
        }

        .right-content {
            .translate-list {
                margin: 0;
                padding: 0;
                list-style: none;

                .translate-item {
                    display: inline-block;
                    font-size: 15px;
                    padding: 0 7px;
                    font-family: 'Roboto-Bold', sans-serif;
                    color: #fff;

                    .name {
                        &:hover {
                            color: #fac033;
                            border-bottom: 2px solid #fac033;
                            cursor: pointer;
                        }
                    }

                    &.active {
                        .name {
                            color: #fac033;
                            border-bottom: 2px solid #fac033;
                        }
                    }

                    & + .translate-item {
                        border-left: 2px solid #fac033;
                    }
                }
            }
        }
    }
}

#telephones-tooltip,
#email-tooltip {
    > .tooltip-inner {
        background: #ffffff;
    }

}

#telephones-tooltip {
    > .tooltip-inner {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-size: 12px;
                font-weight: 600;
                line-height: 22px;

                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }
    > .tooltip-arrow:before {
        border-bottom-color: #ffffff;
    }
}
#email-tooltip {
    > .tooltip-inner {
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;

        a {
            color: #000;
            text-decoration: none;
        }
    }
    > .tooltip-arrow:before {
        border-right-color: #ffffff;
    }
}

@media (max-width: 768px) {
    .information-bar {
        min-height: 100px;
        .top-bar-inner {
            padding: 0;

            .right-content {
                display: flex;
                align-items: center;
            }
        }
    }
}

@media (max-width: 400px) {
    .information-bar {
        .container {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
