.footer-wrapper {
    .top-part {
        background: #1b1918;

        .information-item {
            margin-top: 50px;
            margin-bottom: 50px;

            .title {
                font-family: 'Roboto-Bold', sans-serif;
                font-size: 20px;
                color: #fff;

                &:after {
                    content: '';
                    height: 4px;
                    width: 20px;
                    background: #f3c556;
                    display: block;
                    margin-top: 10px;
                }
            }

            .data-container {
              margin-top: 20px;
              color: #fff;
            }

            .link-container {
                margin-top: 20px;
                list-style: none;
                padding: 0;

                li {
                    padding: 5px 0;
                    color: #f3c556;
                    text-decoration: none;
                    cursor: pointer;

                    &.active {
                        font-weight: 600;
                    }
                }
            }

            .contact-container {
                margin-top: 20px;
                display: flex;

                .telephones,
                .address,
                .email {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: transparent;
                    border: transparent;
                    color: #fac033;
                    margin-right: 3px;

                    &:focus {
                        box-shadow: none;
                    }

                    i {
                        color: #f3c556;
                        padding-right: 5px;
                    }
                }

                .telephones,
                .address {
                    font-size: 16px;
                }
                .email {
                    font-size: 19px;
                }
            }

            .social-container {
                margin-top: 20px;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        display: inline-block;
                        font-size: 15px;
                        margin-right: 15px;

                        i {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .bottom-part {
        color: #fff;
        background: #000;

        p {
            padding: 30px 100px;
            text-align: center;
        }
    }
}

#footer-telephones-tooltip,
#footer-email-tooltip,
#footer-address-tooltip {
    > .tooltip-inner {
        background: #ffffff;
    }

    > .tooltip-arrow:before {
        border-bottom-color: #ffffff;
    }
}

#footer-telephones-tooltip {
    > .tooltip-inner {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-size: 12px;
                font-weight: 600;
                line-height: 22px;

                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }
}

#footer-address-tooltip {
    > .tooltip-inner {
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        color: #000;
    }
}

#footer-email-tooltip {
    > .tooltip-inner {
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;

        a {
            color: #000;
            text-decoration: none;
        }
    }
}


@media (max-width: 768px) {
    .footer-wrapper {
        .top-part {
            .information-item {
                margin: 50px 20px;
            }
        }
    }
}